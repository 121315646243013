import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import Masonry from 'masonry-layout'

export default {
  props: {
    images: {
      required: true,
      type: Array
    },

    imageSize: {
      required: false,
      type: String,
      default: 'origin'
    },

    previewSize: {
      required: false,
      type: String,
      default: 'middle'
    },

    imageWidth: {
      required: false,
      type: String,
      default: '20%'
    },

    editable: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      gallery: null,
      options: {
        bgOpacity: .7,
        index: null,
        getThumbBoundsFn: null
      },
      masonry: null,
      isNewImageAdded: false
    }
  },

  methods: {
    layout(image) {
      if (!this.editable) {
        this.setLayout()

        return
      }

      if (!this.masonry) {
        this.setLayout()
      } else {
        this.isNewImageAdded ? this.layoutNewImage(image) : this.updateLayout()
      }
    },

    setLayout() {
      let options = {
        itemSelector: '.image-container',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        gutter: 0
      }

      this.masonry = new Masonry(this.$refs.imageGalleryContainer, options)

      this.updateLayout()
    },

    updateLayout() {
      this.masonry.layout()
    },

    layoutNewImage(image) {
      this.masonry.prepended(image.closest('.image-container'))

      this.isNewImageAdded = false
    },

    openSlide(slide) {
      this.options.index = this.slides.findIndex(s => s.src === slide.src)
      this.options.getThumbBoundsFn = index => {
        let thumbnail = this.$refs.imageGalleryImg[index]
        let pageYScroll = window.pageYOffset || document.documentElement.scrollTop
        let rect = thumbnail.getBoundingClientRect()

        return {
          x: rect.left,
          y: rect.top + pageYScroll,
          w: rect.width
        }
      }

      this.slideshow = new PhotoSwipe(this.$refs.photoswipe, PhotoSwipeUI_Default, this.slides, this.options)

      this.slideshow.init()
    },

    getImageInfo(imageId) {
      return this.images.find(image => image.image_id === imageId)
    }
  },

  computed: {
    slides() {
      return this.images.map(image => {
        let fullSizeImage = image.slices_info.find(info => info.title === this.imageSize)
        let previewImage = image.slices_info.find(info => info.title === this.previewSize)

        return {
          src: fullSizeImage.url,
          w: fullSizeImage.width,
          h: fullSizeImage.height,
          msrc: previewImage.url,
          id: image.image_id
        }
      })
    }
  },

  watch: {
    slides(val, oldVal) {
      if (!this.masonry) {
        return
      }

      if (val.length > oldVal.length) {
        this.isNewImageAdded = true
      }
    }
  }
}